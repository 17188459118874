import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import UploadMedia from "../../../components/UploadMedia";
import Void from "../../../components/Void";
import UploadButton from "../../../components/VoucherUploader";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredOrders(){
    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, filterDataQuery } = useContext(FilterDataContext);
    
    const { url, customer, token, portal, contact  } = useContext(GlobalContext);

    const title = "Filtered Orders"

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, filterDataQuery})

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/vouchers/search?page=' + page, {
            customer: customer,
            contact: contact,
            creator: portal == "supplier" ? 2 : 3,
            seller: portal === "supplier" ? customer : filterDataSupplier?.id,
            buyer: portal === "buyer" ? customer : filterDataBuyer?.id,
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            query: filterDataQuery,
            status: filterDataStatus==="allData" ? "" : filterDataStatus.toString(),
            voucher_type: "orders",
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredOrders',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { name: "Orders", status: { 1: "Ordered", 2: "Invoiced", 3: "Completed", 4: "Voided", 5: "Cancelled", 99: "All Data",}};


    const Item = ({data}) => {

        let voided = 0;
        if( filterDataStatus == 0 ) {
            if(data.status == 6) {
                voided = 1;
            }
        } else {
            if(data.status == 3) {
                voided = 1;
            }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                
                { filterDataStatus === "allData" &&  
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}> <Chip  label={data?.status_label}  /> </Box>
                }

                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Order #</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data.voucher_no ? data.voucher_no : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {data.total ? data.total : ''}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ borderTop: 1,  borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.images} /> 
                </Box> 
                
                {
                    filterDataStatus == 0 && (portal == "supplier")  &&
                    <Box sx={{ borderTop: 1,  borderColor: 'divider', padding: "5px 5px 0 5px", }}>
                        <UploadMedia 
                            id={data.id} module="entry"
                            dir="orders" link={url + 'api/media/add'}
                            refetch={refetch}
                        />
                    </Box>
                }
                

                { (filterDataStatus == 1 || data?.status_label === "Ordered") && portal == "supplier" &&
                    <Box sx={{ borderTop: 1,  borderColor: 'divider', padding: "5px", }}>
                        <Button variant="contained"  fullWidth onClick={() => { if(filterDataStatus != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                    </Box>
                }
               
                {!(data?.status_label == "Voided") && !(data?.status_label == "Cancelled") &&
                    <Box sx={{ display: 'flex', justifyContent:"center", alignContent: "stretch", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1 }}>
                        { (filterDataStatus == 1 || data?.status_label === "Ordered") && portal == "buyer" &&
                            <Box sx={{ flex:1, flexGrow: 1, }}>
                                <Button sx={{height: "100%"}} variant="contained"  fullWidth onClick={() => { if(filterDataStatus != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                            </Box>
                        }
                        {portal == "supplier" && data.temp_link == null &&
                            <Box sx={{flexGrow: 1, flex: 1}}>
                                <UploadButton 
                                    title="Invoice" buyer={data.buyer} 
                                    seller={data.seller} temp_link={data.hasOwnProperty('entry_id') ? data.entry_id : data.id } 
                                    link_with="entry" voucher_type="invoices" refetch={refetch}
                                />
                            </Box>
                        }
                        { (data?.order_status == 1 || data?.order_status == 2) && 
                            <Box sx={{flex:1, flexGrow: 1, }}>
                                <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { if(filterDataStatus != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                            </Box>
                        }                    
                        
                        <Box sx={{flex:1, flexGrow: 1,}}>
                            <Void styleObj={{height: "100%"}} refetch={refetch} id={data.id} type={ filterDataStatus == 0 ? 'entry' : 'voucher' } voided={data.status != 5 ? 0 : 1 } />
                        </Box>
                    </Box>
                }
                
            </Paper>
        )
    }

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToTop={true} >                
                    <Box>
                        <Alert severity="success" >
                            <AlertTitle sx={{fontSize: "0.5 em"}}> Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}</AlertTitle>
                            {(filterDataFromDate && filterDataToDate) && 
                            ` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`
                            }    
                            <Box sx={{mt: 1}}>
                            { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                            </Box>
                            {!!filterDataQuery &&  
                                <Box sx={{mt: 1}}>
                                    {`Search Query: ${filterDataQuery}`}
                                </Box>
                            }
                        </Alert>                    
                    </Box> 
                                   
                    <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    <BackToTop alone={true} />
                </Layout>
                }
                </>
            } 
        </>       
    )
}